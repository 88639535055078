<template>
  <div class="problem">
    <div class="header">
      <div class="left">
        <van-icon name="arrow-left" @click="gopath('back')" class="icon" />
      </div>
      <div class="title">常见问题</div>
    </div>
    <div class="list">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
        @click="gopath(item.commonProblemId)"
      >
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
import { problem } from "@/api/user";
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      problem().then((res) => {
        this.list = res.data.data;
      });
    },
    gopath(val) {
      if (val == "back") {
        this.$router.go(-1);
      } else {
        this.$router.push({
          path: "/me/detail",
          query: { commonProblemId: val },
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  padding: 10px 10px;
  display: flex;
  .left {
    width: 10%;
    .icon {
      font-size: 24px;
      font-weight: 550;
      color: #141414;
    }
  }
  .title {
    width: 85%;
    text-align: center;
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #141414;
  }
}
.list {
  padding: 15px 14px;
  .item {
    padding: 12px 0;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #141414;
    line-height: 20px;
    border-bottom: 1px solid #dddddd;
  }
}
</style>